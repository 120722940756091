export default [
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    adminAuth: true,
    children: [
      {
        title: 'Catalogs',
        route: 'catalogs',
        icon: 'BoxIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'BoxIcon',
      },
    ],
  },
]
